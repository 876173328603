<template>
  <el-checkbox v-bind="{ ...$attrs, ...props }" class="ui-checkbox">
    <template #default>
      <slot name="default"></slot>
    </template>
  </el-checkbox>
</template>
<script setup lang="ts">
import { checkboxProps } from 'element-plus'
const props = defineProps({
  ...checkboxProps,
})
</script>
<style lang="postcss" scoped>
.ui-checkbox.el-checkbox {
  --el-checkbox-height: 1.5rem; /* 24px */

  --el-checkbox-font-size: 0.75rem; /* 12px */
  --el-checkbox-font-weight: 400;
  --el-checkbox-text-color: var(--brand-color-text-tertiary);
  --el-checkbox-input-height: 1rem;
  --el-checkbox-input-width: 1rem;
  --el-checkbox-border-radius: 4px;
  --el-checkbox-bg-color: transparent;
  --el-checkbox-input-border: 1px solid var(--brand-color-text-tertiary);

  --el-checkbox-disabled-border-color: var(--brand-color-base-secondary);
  --el-checkbox-disabled-input-fill: transparent;
  --el-checkbox-disabled-icon-color: var(--brand-color-base-tertiary);
  --el-disabled-text-color: var(--el-checkbox-disabled-border-color);

  --el-checkbox-disabled-checked-input-fill: var(--brand-color-base-secondary);
  --el-checkbox-disabled-checked-input-border-color: var(
    --brand-color-base-secondary
  );
  --el-checkbox-disabled-checked-icon-color: var(--brand-color-base-primary);

  --el-checkbox-checked-text-color: var(--brand-color-text-primary);
  --el-checkbox-checked-input-border-color: var(--brand-color-primary);
  --el-checkbox-checked-bg-color: var(--brand-color-primary);
  --el-checkbox-checked-icon-color: var(--brand-color-base-primary);

  --el-checkbox-input-border-color-hover: var(--brand-color-text-secondary);

  height: auto;
  min-height: var(--el-checkbox-height);
  font-family: var(--font-family-primary);

  &:hover {
    --el-checkbox-text-color: var(--brand-color-text-secondary);
    &:deep(
        .el-checkbox__input:not(.is-checked):not(.is-indeterminate):not(
            .is-disabled
          )
          > .el-checkbox__inner
      ) {
      border-color: var(--el-checkbox-input-border-color-hover);
    }
  }
  &:deep(.el-checkbox__input) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem; /* 24px */
    height: 1.5rem; /* 24px */
  }
  &:deep(
      .el-checkbox__input.is-checked:not(.is-indeterminate):not(
          .is-disabled
        ):not(.is-focus)
        > .el-checkbox__inner
    ) {
    border-color: var(--el-checkbox-checked-input-border-color);
  }
  &:deep(.el-checkbox__inner) {
    transition: none;
  }
  &:deep(.el-checkbox__inner::before) {
    height: 4px;
  }
  &:deep(.el-checkbox__inner::after) {
    top: 1px;
    left: 4px;
    height: calc(var(--el-checkbox-input-height) / 2);
    border-width: 2px;
    border-radius: 1px;
  }
  &:deep(.el-checkbox__label) {
    padding-left: 0.25rem; /* 4px */
    line-height: 1rem; /* 16px */
    &:empty {
      padding-left: 0;
    }
  }
  &:deep(input:focus-visible) {
    & + .el-checkbox__inner {
      outline: 1px solid var(--brand-color-primary-focus);
      outline-offset: 0;
    }
  }
}
</style>
